import '@fluidtruck/core/dist/css/date-time-range-picker.css'

import { createStandaloneToast, TidalProvider } from '@fluidtruck/core'
import FontFace from 'components/font-face'
import Head from 'next/head'
import { DefaultSeo } from 'next-seo'
import React from 'react'
import theme from 'theme'
import { getSeo } from 'utils/seo'

const App = ({ Component, pageProps }) => {
  const seo = getSeo()
  const { ToastContainer } = createStandaloneToast({ theme })
  return (
    <>
      <Head>
        <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <link href="/favicon.png" rel="icon" sizes="96x96" type="image/png" />
        <link href="https://fonts.googleapis.com" rel="preconnect" />
        <link href="https://static.cloudflareinsights.com" rel="preconnect" />
        <meta content="#319795" name="theme-color" />
        {process.env.NODE_ENV === 'production' && (
          <script
            async
            data-domain="tidal.fluidtruck.com"
            defer
            src="https://plausible.io/js/plausible.js"
          />
        )}
      </Head>
      <DefaultSeo {...seo} />
      <ToastContainer />
      <TidalProvider theme={theme}>
        <Component {...pageProps} />
      </TidalProvider>
      <FontFace />
    </>
  )
}

export default App
